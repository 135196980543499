import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import pic1 from "./../../../../images/card/pic1.jpg";
import pic2 from "./../../../../images/card/pic2.jpg";
import pic3 from "./../../../../images/card/pic3.jpg";
import pic4 from "./../../../../images/card/pic4.jpg";

const sliderBlog = [
  { image: pic1 },
  { image: pic2 },
  { image: pic3 },
  { image: pic4 },
];

const ProfileSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider
        className="front-view-slider owl-carousel owl-carousel owl-loaded owl-drag owl-dot"
        {...settings}
      >
        {sliderBlog.map((data, index) => (
          <div className="items px-3" key={index}>
            <div className="front-view">
              <img src={data.image} alt="" className="w-100" />
              <div className="sale">
                <span>
                  <i className="far fa-flag me-2"></i>FOR SALE
                </span>
              </div>
              <div className="info">
                <h3 className="mb-3 text-white font-w600">
                  221 XYZ Franklin State Residence London
                </h3>
                <div>
                  <span className="me-4  font-w500 text-white">
                    <svg
                      className="me-2"
                      width="28"
                      height="19"
                      viewBox="0 0 28 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.1 8.03846C25.7498 8.03846 28 10.2859 28 13.1538V17.5385H25.9V19H24.5V17.5385H3.5V19H2.1V17.5385H0V13.1538C0 10.3876 2.17398 8.03846 4.9 8.03846H23.1ZM21.7 0C23.5821 0 25.2005 1.57962 25.2 3.65385L25.2005 7.14522C24.5639 6.78083 23.8517 6.57692 23.1 6.57692L21.7 6.57618C21.7 5.32466 20.7184 4.38462 19.6 4.38462H15.4C14.8622 4.38462 14.3716 4.59567 14.0001 4.94278C13.629 4.59593 13.1381 4.38462 12.6 4.38462H8.4C7.24044 4.38462 6.30038 5.36575 6.3 6.57619L4.9 6.57692C4.14851 6.57692 3.43653 6.7807 2.8 7.14488V3.65385C2.8 1.68899 4.3096 0 6.3 0H21.7ZM12.6 5.84579C12.9799 5.84579 13.3 6.21117 13.3 6.57692L7.7 6.57618C7.7 6.12909 8.04101 5.84615 8.4 5.84615L12.6 5.84579ZM19.6 5.85107C19.9961 5.84578 20.2996 6.20175 20.3 6.57618H14.7C14.7 6.1227 15.041 5.84615 15.4 5.84615L19.6 5.85107Z"
                        fill="white"
                        fillOpacity="0.6"
                      />
                    </svg>
                    4 Bedroom
                  </span>
                  <span className="me-4 font-w500 text-white">
                    <svg
                      className="me-2"
                      width="19"
                      height="22"
                      viewBox="0 0 19 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 10.4211L18.6388 12.249C18.0616 15.1706 15.4406 17.3684 12.5829 17.3684H11.6923L13.4082 22H2.28779V10.4211H19ZM5.14753 0C6.68536 0 8.00727 1.29706 8.00727 2.89474V7.52632H18.8743V8.68421H8.00727V9.26316H1.1439L1.14378 11.0001C0.481336 10.4964 0 9.64309 0 8.68421V2.89474C0 1.33809 1.25234 0 2.85974 0H5.14753Z"
                        fill="white"
                        fillOpacity="0.6"
                      />
                    </svg>
                    2 Bathroom
                  </span>
                  <span className=" font-w500 text-white">
                    <svg
                      className="me-2"
                      width="23"
                      height="16"
                      viewBox="0 0 23 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.9018 4.49675C20.4365 3.03139 18.7276 1.89671 16.8227 1.12424C14.9831 0.378271 13.0394 0 11.0457 0C9.052 0 7.10835 0.378271 5.26872 1.12424C3.36382 1.89671 1.65494 3.03139 0.189572 4.49675C-0.0631836 4.74947 -0.0631836 5.15923 0.189529 5.41203L2.08006 7.30265C2.20144 7.42403 2.36609 7.49224 2.53768 7.49224H2.53773C2.70937 7.49224 2.87397 7.42407 2.99534 7.30265C5.14567 5.15242 8.00469 3.9682 11.0457 3.9682C14.0866 3.9682 16.9456 5.15242 19.0959 7.30265C19.2172 7.42403 19.3819 7.49224 19.5535 7.49224C19.7251 7.49224 19.8898 7.42407 20.0111 7.30265L21.9016 5.41203C22.1546 5.15928 22.1546 4.74951 21.9018 4.49675Z"
                        fill="white"
                        fillOpacity="0.6"
                      />
                      <path
                        d="M11.0457 5.34747C8.37317 5.34747 5.86058 6.38818 3.97082 8.27794C3.71807 8.53065 3.71807 8.94042 3.97078 9.19321L5.86131 11.0838C6.11407 11.3366 6.52384 11.3365 6.77659 11.0839C7.91693 9.94367 9.43299 9.31575 11.0456 9.31575C12.6582 9.31575 14.1743 9.94371 15.3146 11.0839C15.436 11.2053 15.6006 11.2735 15.7723 11.2735C15.9439 11.2735 16.1085 11.2053 16.2299 11.0839L18.1203 9.19317C18.373 8.94046 18.373 8.53069 18.1203 8.27794C16.2307 6.38818 13.7181 5.34747 11.0457 5.34747Z"
                        fill="white"
                        fillOpacity="0.6"
                      />
                      <path
                        d="M11.0457 10.6951C9.80158 10.6951 8.6319 11.1795 7.75209 12.0592C7.63072 12.1806 7.5625 12.3452 7.5625 12.5169C7.5625 12.6885 7.63067 12.8532 7.75209 12.9745L10.588 15.8104C10.7144 15.9368 10.88 16 11.0456 16C11.2112 16 11.3769 15.9368 11.5033 15.8104L14.3393 12.9745C14.4607 12.8531 14.5289 12.6885 14.5289 12.5169C14.5289 12.3452 14.4607 12.1806 14.3393 12.0593C13.4595 11.1796 12.2898 10.6951 11.0457 10.6951Z"
                        fill="white"
                        fillOpacity="0.6"
                      />
                    </svg>
                    Wifi Available
                  </span>
                </div>
              </div>
              <div className="buttons">
                <Link to={"#"} className="mb-4">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.9999 15C17.3708 15.0015 16.7509 15.1517 16.1909 15.4385C15.631 15.7252 15.1468 16.1404 14.7779 16.65L9.76288 13.307C10.0789 12.4643 10.0789 11.5357 9.76288 10.693L14.7779 7.34999C15.3408 8.11312 16.1574 8.65051 17.0809 8.86561C18.0045 9.08072 18.9745 8.95943 19.8166 8.52354C20.6588 8.08766 21.3179 7.36574 21.6755 6.48751C22.0332 5.60927 22.066 4.63227 21.7679 3.73205C21.4699 2.83183 20.8606 2.06739 20.0495 1.57608C19.2385 1.08476 18.2788 0.898777 17.3429 1.05154C16.407 1.2043 15.5563 1.68579 14.9435 2.4095C14.3308 3.13322 13.9962 4.05172 13.9999 4.99999C14.0064 5.1457 14.0211 5.29093 14.0439 5.43499L8.65288 9.02899C8.07837 8.51108 7.36602 8.17085 6.60209 8.04951C5.83817 7.92817 5.05546 8.03092 4.34874 8.34532C3.64202 8.65973 3.04162 9.17229 2.62025 9.82094C2.19888 10.4696 1.97461 11.2265 1.97461 12C1.97461 12.7735 2.19888 13.5304 2.62025 14.179C3.04162 14.8277 3.64202 15.3402 4.34874 15.6546C5.05546 15.969 5.83817 16.0718 6.60209 15.9505C7.36602 15.8291 8.07837 15.4889 8.65288 14.971L14.0439 18.565C14.0211 18.709 14.0064 18.8543 13.9999 19C13.9999 19.7911 14.2345 20.5645 14.674 21.2223C15.1135 21.8801 15.7382 22.3928 16.4692 22.6955C17.2001 22.9983 18.0043 23.0775 18.7802 22.9231C19.5562 22.7688 20.2689 22.3878 20.8283 21.8284C21.3877 21.269 21.7687 20.5563 21.923 19.7803C22.0774 19.0044 21.9982 18.2002 21.6954 17.4693C21.3927 16.7383 20.88 16.1136 20.2222 15.6741C19.5644 15.2346 18.791 15 17.9999 15Z"
                      fill="white"
                    />
                  </svg>
                </Link>
                <Link to={"#"}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.1152 2.4H8.4032V0H0V8.4032H2.4V4.0832L8.7616 10.4384L10.464 8.7488L4.1152 2.4Z"
                      fill="white"
                    />
                    <path
                      d="M10.4384 15.2384L8.736 13.536L2.4 19.872V15.6032H0V24H8.4032V21.6H4.0768L10.4384 15.2384Z"
                      fill="white"
                    />
                    <path
                      d="M15.6032 0V2.4H19.8848L13.5488 8.7488L15.2384 10.4384L21.6 4.0704V8.4032H24V0H15.6032Z"
                      fill="white"
                    />
                    <path
                      d="M21.5999 15.6032V19.8848L15.2639 13.5488L13.5615 15.2384L19.9167 21.6H15.6031V24H23.9999V15.6032H21.5999Z"
                      fill="white"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};
export default ProfileSlider;
