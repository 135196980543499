import React, { Fragment, useEffect, useState } from "react";
// import { Table, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getUsers } from "../../../services/PostsService";
import { Badge, Button, Modal } from "react-bootstrap";
import axiosInstance from "../../../services/AxiosInstance";
import UiBadge from "../bootstrap/Badge";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const CheckBoxes = ({ handleCheckbox, checkboxes }) => {
  return (
    <>
      <div className="row mb-3">
        <h4>Admin</h4>
        <div className="col mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="checkbox"
            value="read_admin"
            checked={checkboxes.read_admin}
            onChange={handleCheckbox}
          />
          <label className="form-check-label" htmlFor="checkbox">
            Read Admin
          </label>
        </div>
        <div className="col mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="checkbox2"
            value="create_admin"
            checked={checkboxes.create_admin}
            onChange={handleCheckbox}
          />
          <label className="form-check-label" htmlFor="checkbox2">
            Create Admin
          </label>
        </div>
        <div className="col mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="checkbox3"
            value="update_admin"
            checked={checkboxes.update_admin}
            onChange={handleCheckbox}
          />
          <label className="form-check-label" htmlFor="checkbox3">
            Update Admin
          </label>
        </div>
        <div className="col mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="checkbox4"
            value="delete_admin"
            checked={checkboxes.delete_admin}
            onChange={handleCheckbox}
          />
          <label className="form-check-label" htmlFor="checkbox4">
            Delete Admin
          </label>
        </div>
      </div>
      <div className="row mb-3">
        <h4>User</h4>
        <div className="col mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="checkbox5"
            value="read_user"
            checked={checkboxes.read_user}
            onChange={handleCheckbox}
          />
          <label className="form-check-label" htmlFor="checkbox5">
            Read User
          </label>
        </div>
        <div className="col mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="checkbox6"
            value="create_user"
            checked={checkboxes.create_user}
            onChange={handleCheckbox}
          />
          <label className="form-check-label" htmlFor="checkbox6">
            Create User
          </label>
        </div>
        <div className="col mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="checkbox7"
            value="update_user"
            checked={checkboxes.update_user}
            onChange={handleCheckbox}
          />
          <label className="form-check-label" htmlFor="checkbox7">
            Update User
          </label>
        </div>
        <div className="col mb-3 form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="checkbox8"
            value="delete_user"
            checked={checkboxes.delete_user}
            onChange={handleCheckbox}
          />
          <label className="form-check-label" htmlFor="checkbox8">
            Delete User
          </label>
        </div>
      </div>
    </>
  );
};
const ProfileDatatable = (props) => {
  const [data2, setData2] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [password, setPassword] = useState(undefined);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState([]);
  const [editModel, setEditModel] = useState(false);
  const [createModel, setCreateModel] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [delModel, setDelModel] = useState(false);
  const [checkboxes, setCheckboxes] = useState({
    read_admin: false,
    create_admin: false,
    update_admin: false,
    delete_admin: false,
    read_user: false,
    create_user: false,
    update_user: false,
    delete_user: false,
  });

  const fetchData = async (props) => {
    try {
      const response = await getUsers(props.role);
      setData2(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data");
      setLoading(false);
    }
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleUsername = (e) => {
    setUsername(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleCheckbox = (e) => {
    const { value, checked } = e.target;
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [value]: checked,
    }));
  };

  React.useEffect(() => {
    const values = Object.keys(checkboxes).filter((key) => checkboxes[key]);
    setRoles(values);
  }, [checkboxes]);

  useEffect(() => {
    console.log("useEffect");
    fetchData(props);
  }, []);

  const renderValue = (value, i) => {
    if (i === 4 || i === 5) {
      const date = new Date(value); // Convert from seconds to milliseconds
      return (
        <Fragment>
          {date.toLocaleString()} {/* Convert to local time format */}
        </Fragment>
      );
    } else if (i === 3) {
      return (
        <Fragment>
          {value.map((val) => (
            <Fragment key={val}>
              <Badge variant="primary" className="me-1" key={val}>
                {val
                  .split("_")
                  .map((v) => Array.from(v)[0].toUpperCase())
                  .join("")}
              </Badge>
            </Fragment>
          ))}
        </Fragment>
      );
    } else {
      return <Fragment>{value}</Fragment>;
    }
  };

  const handleEditSubmit = (obj) => {
    setCurrentData(obj);
    setUsername(obj.username);
    setEmail(obj.email);
    const roles = obj.allowed_roles;
    const updatedCheckboxes = { ...checkboxes };
    for (const role of roles) {
      updatedCheckboxes[role] = true;
    }
    console.log(updatedCheckboxes);
    setCheckboxes(updatedCheckboxes);
    setEditModel(true);
  };

  const handleCreateSubmit = () => {
    setCheckboxes({
      read_admin: false,
      create_admin: false,
      update_admin: false,
      delete_admin: false,
      read_user: false,
      create_user: false,
      update_user: false,
      delete_user: false,
    });
    setCreateModel(true);
  };
  const handleDeleteSubmit = (obj) => {
    setCurrentData(obj);
    setDelModel(true);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    axiosInstance
      .put(props.role + "/" + currentData.id, {
        id: currentData.id,
        username: username,
        email: email,
        password: password,
        allowed_roles: roles,
      })
      .then((response) => {
        if (response.status === 200) {
          fetchData(props);
          setEditModel(false);
          toast.success("User Updated Successfully");
        }
      })
      .catch((error) => {
        setEditModel(false);
        toast.error(
          "An Error Occurred. Please try again or Check your permissions.",
        );
      });
  };

  if (loading) return <p>Loading...</p>;
  if (data2.length === 0) return <p>No data available</p>;

  function handleDeleteFormSubmit(e) {
    e.preventDefault();
    axiosInstance
      .delete(props.role + "/" + currentData.id)
      .then((response) => {
        fetchData(props);
        setDelModel(false);
        toast.success("User Deleted Successfully");
      })
      .catch((error) => {
        setDelModel(false);
        toast.error(
          "An Error Occurred. Please try again or Check your permissions.",
        );
      });
  }

  function handleCreateFormSubmit(e) {
    e.preventDefault();
    axiosInstance
      .post(props.role, {
        username: username,
        email: email,
        password: password,
        allowed_roles: roles,
      })
      .then((response) => {
        console.log(response);
        fetchData(props);
        toast.success("User Created Successfully");

        setCreateModel(false);
      })
      .catch((error) => {
        setCreateModel(false);
        toast.error(
          "An Error Occurred. Please try again or Check your permissions.",
        );
      });
  }

  return (
    <div className="col-12">
      <Modal className="fade bd-example-modal-lg" show={createModel} size="lg">
        <Modal.Header>
          <Modal.Title>Create New {props.role}</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setCreateModel(false);
              setCheckboxes({
                read_admin: false,
                create_admin: false,
                update_admin: false,
                delete_admin: false,
                read_user: false,
                create_user: false,
                update_user: false,
                delete_user: false,
              });
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="InputEmail" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="InputEmail"
                aria-describedby="emailHelp"
                onChange={handleEmail}
                value={email}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="InputUsername" className="form-label">
                Username
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                aria-describedby="emailHelp"
                onChange={handleUsername}
                value={username}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="InputPassword" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                onChange={handlePassword}
              />
            </div>
            {props.role === "admin" && (
              <CheckBoxes
                handleCheckbox={handleCheckbox}
                checkboxes={checkboxes}
              />
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger light"
            onClick={() => {
              setEditModel(false);
              setCheckboxes({
                read_admin: false,
                create_admin: false,
                update_admin: false,
                delete_admin: false,
                read_user: false,
                create_user: false,
                update_user: false,
                delete_user: false,
              });
            }}
          >
            Close
          </Button>
          <Button
            variant=""
            type="button"
            className="btn btn-primary"
            onClick={handleCreateFormSubmit}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="fade bd-example-modal-lg" show={editModel} size="lg">
        <Modal.Header>
          <Modal.Title>Edit User</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setEditModel(false);
              setCheckboxes({
                read_admin: false,
                create_admin: false,
                update_admin: false,
                delete_admin: false,
                read_user: false,
                create_user: false,
                update_user: false,
                delete_user: false,
              });
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="InputEmail" className="form-label">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="InputEmail"
                aria-describedby="emailHelp"
                onChange={handleEmail}
                value={email}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="InputUsername" className="form-label">
                Username
              </label>
              <input
                type="text"
                className="form-control"
                id="username"
                aria-describedby="emailHelp"
                onChange={handleUsername}
                value={username}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="InputPassword" className="form-label">
                Password
              </label>
              <input
                type="password"
                className="form-control"
                id="password"
                onChange={handlePassword}
              />
            </div>
            {props.role === "admin" && (
              <CheckBoxes
                handleCheckbox={handleCheckbox}
                checkboxes={checkboxes}
              />
            )}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger light"
            onClick={() => {
              setEditModel(false);
              setCheckboxes({
                read_admin: false,
                create_admin: false,
                update_admin: false,
                delete_admin: false,
                read_user: false,
                create_user: false,
                update_user: false,
                delete_user: false,
              });
            }}
          >
            Close
          </Button>
          <Button
            variant=""
            type="button"
            className="btn btn-primary"
            onClick={handleFormSubmit}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="fade bd-example-modal-lg" show={delModel} size="lg">
        <Modal.Header>
          <Modal.Title>Delete User</Modal.Title>
          <Button
            variant=""
            className="btn-close"
            onClick={() => {
              setDelModel(false);
              setCheckboxes({
                read_admin: false,
                create_admin: false,
                update_admin: false,
                delete_admin: false,
                read_user: false,
                create_user: false,
                update_user: false,
                delete_user: false,
              });
            }}
          ></Button>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              setDelModel(false);
              setCheckboxes({
                read_admin: false,
                create_admin: false,
                update_admin: false,
                delete_admin: false,
                read_user: false,
                create_user: false,
                update_user: false,
                delete_user: false,
              });
            }}
          >
            Close
          </Button>
          <Button
            variant="danger"
            type="button"
            className="btn btn-primary btn-reddit"
            onClick={handleDeleteFormSubmit}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{props.role}s</h4>
          {/*^^^ Admins, SuperAdmins, Users etc.*/}
          <Button
            variant="primary"
            className="btn btn-primary shadow  sharp float-end"
            onClick={() => setCreateModel(true)}
          >
            Add {props.role}
          </Button>
        </div>
        <div className="card-body">
          <div className="w-100 table-responsive">
            <div id="example_wrapper" className="dataTables_wrapper">
              <table id="example" className="display w-100 dataTable">
                <thead>
                  <tr role="row">
                    {Object.keys(data2[0]).map((key, i) => (
                      <React.Fragment key={i}>
                        <th>{key}</th>
                        {i === Object.keys(data2[0]).length - 1 && (
                          <th>Edit</th>
                        )}
                        {i === Object.keys(data2[0]).length - 1 && (
                          <th>Delete</th>
                        )}
                      </React.Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data2.map((obj, index) => (
                    <tr key={obj.id}>
                      {Object.values(obj).map((value, i) => (
                        <Fragment key={i}>
                          <td key={i}>{renderValue(value, i)}</td>
                          {i === 6 && (
                            <>
                              <td>
                                <Link
                                  to="#"
                                  className="btn btn-primary shadow btn-xs sharp me-1"
                                  onClick={() => handleEditSubmit(obj)}
                                >
                                  <i className="fas fa-pen"></i>
                                </Link>
                              </td>
                              <td>
                                <Link
                                  to="#"
                                  className="btn btn-danger shadow btn-xs sharp"
                                  onClick={() => handleDeleteSubmit(obj)}
                                >
                                  <i className="fa fa-trash"></i>
                                </Link>
                              </td>
                            </>
                          )}
                        </Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
                {/* <tfoot>
                                    <tr role="row">
                                        {Object.keys(data2[0]).map((key, i) => (
                                            <th key={i}>{key}</th>
                                        ))}

                                    </tr>
                                </tfoot> */}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDatatable;
