import axios from "axios";
import { store } from "../store/store";

const axiosInstance = axios.create({
  baseURL: `https://apix.aajivika.org`,
  //baseURL: `http://localhost:8000`,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = localStorage.getItem("userDetails")
    ? JSON.parse(localStorage.getItem("userDetails")).token
    : null;
  config.params = config.params || {};
  config.headers["authorization"] = `Bearer ${token}`;
  return config;
});

export default axiosInstance;
